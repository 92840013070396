import { Table as AntTable,Typography  } from 'antd';
import { useEffect, useState } from 'react';
import { dateFormat } from 'utils/helpers';
const { Text } = Typography;
const Table = ({ columns, dataSource }) => {
  // const [columns, setColumns] = useState(prevColumns);
  // useEffect(() => {
    // const newColumns = prevColumns.map((item) => {
    //   if (item.key === 'created_at' || item.key === 'updated_at') {
    //     return {
    //       ...item,
    //       render: (_) => {
    //         const date = new Date(_);
    //         return dateFormat(date);
    //       },
    //     };
    //   } else return item;
    // });
  //   setColumns(prevColumns);
  // }, [prevColumns]);
  return (
    <AntTable
      columns={columns}
      dataSource={dataSource}
      scroll={{ x: 'max-content' }}
      pagination={false}
      summary={pageData => {
        if(pageData.length === 0) return
        let total = {}
        Object.keys(pageData[0]).map(key =>{
          total[key] = 0
        })
        pageData.forEach((item) => {
          Object.keys(item).map(key =>{
            total[key] += item[key]
          })
        });
        delete total['key']
        return (
          <>
            <AntTable.Summary.Row>
              <AntTable.Summary.Cell><b>Total</b></AntTable.Summary.Cell>
              {Object.keys(total).map(key =>{
               return <AntTable.Summary.Cell>
                  <Text type="danger">{typeof total[key] === 'string' ? '-' :  total[key] }</Text>
                </AntTable.Summary.Cell>
              })}
       
            </AntTable.Summary.Row>
          </>
        );
      }}
    />
  );
};
export default Table;
