import { DatePicker, Form } from 'antd';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const SelectDateTime = ({
  name,
  form,
  options: prevOptions,
  required,
  ...props
}) => {
  const show_interval = Form.useWatch('show_interval', form);
  const format = 'YYYY-MM-DD HH:mm';
  useEffect(() => {
    if (show_interval) {
      const start = show_interval.split('to')[0];
      const end = show_interval.split('to')[1];
      if (name === 'start_time') {
        form.setFieldValue(name, dayjs(`${start} 00:00`, format));
      } else if (name === 'end_time') {
        form.setFieldValue(name, dayjs(`${end} 23:59`, format));
      }
    }
  }, [show_interval]);
  return (
    <Form.Item
      style={{
        width: 'max-content',
      }}
      label={props.label}
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <DatePicker
        showTime={{
          format: 'HH:mm',
        }}
        format={format}
        onSelect={(_) => {
          form.setFieldValue(name, _);
        }}
      />
    </Form.Item>
  );
};
export default SelectDateTime;
