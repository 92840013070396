import { Form, Select, Tag } from 'antd';
import React from 'react';
const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={'#1677FF'}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        margin: 3,
      }}
    >
      {label}
    </Tag>
  );
};
const MultipleSelect = ({ name, form, options, required, ...props }) => {
  React.useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);

  return (
    <Form.Item
      label={props.label}
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <Select
        notFoundContent={props.notFoundContent}
        placeholder={`${props.label} сонгоно уу`}
        allowClear
        options={options}
        mode='multiple'
        showArrow
        tagRender={tagRender}
      />
    </Form.Item>
  );
};

export default MultipleSelect;
