import axios from './axios';
import configJson from '../config.json';
import { errorDetail } from './helpers';
import { NotificationManager } from 'react-notifications';

const Request = async ({
  method = 'post',
  url,
  hideError,
  hideSuccess,
  successMsg,
  body = {},
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      // START LOADER
      let urlType = configJson.PROD_SERVER_URL;
      let axiosObject;
      if (method === 'post') {
        axiosObject = [{ ...body }];
      }
      const result = await axios[method](urlType + url, ...axiosObject);
      resolve(result);
      if (hideSuccess) return;
      NotificationManager.success(
        'Амжилттай',
        successMsg ?? 'Амжилттай хадгалагдлаа'
      );
    } catch (e) {
      const error = await errorDetail(e);
      reject(error);
      if (hideError) return;
      NotificationManager.error(error, 'Error');
    }
  });
};

export default Request;
