import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import Request from 'utils/Request';
const Menus = () => {
  const [menuItems, setMenusItems] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await Request({
      url: '/group/getGroup',
      hideSuccess: true,
    });

    if (data.statusCode === 400) {
      NotificationManager.error(data.message);
    }
    const menus = data?.result?.map((menu) => {
      let parentMenu = {
        label: menu.group_name,
        key: menu.group_name,
        children: [],
      };
      menu?.Reports.forEach((report) => {
        parentMenu.children.push({
          label: report.title,
          key: report.report_name,
        });
      });
      return parentMenu;
    });
    setMenusItems(menus);
  };

  const navigate = useNavigate();
  const handleMenu = (ev) => {
    navigate(`reports/${ev.key}`);
  };
  return (
    <Menu theme='dark' mode='inline' items={menuItems} onClick={handleMenu} />
  );
};
export default Menus;
