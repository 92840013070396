import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/__Login';
import * as theme from './utils/theme';
import { ThemeProvider } from 'styled-components';
import { AuthContextProvider } from './contexts/auth';
import { ReferenceContextProvider } from './contexts/reference';
import { NotificationContainer } from 'react-notifications';
import { Amplify } from 'aws-amplify';
import configFile from 'config.json';
import AuthEntry from 'pages/entry';
import Reports from 'components/Reports';
import Profile from 'components/Profile';
import Logout from 'components/Logout';

Amplify.configure(configFile.awsmobile);

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <ReferenceContextProvider>
            <Routes>
              <Route path='/' element={<AuthEntry />}>
                <Route path='/reports/:report' element={<Reports />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/logout' element={<Logout />} />
              </Route>
              <Route path='/login' element={<Login />} />
            </Routes>
          </ReferenceContextProvider>
        </AuthContextProvider>
        <NotificationContainer />
      </ThemeProvider>
    </Router>
  );
}

export default App;
