import { Button, Form } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { SelectDateTime, SelectInput } from 'components/Form/InputTypes';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { complaint_list } from 'utils/activeColumns';
import { dateFormat } from 'utils/helpers';
import Request from 'utils/Request';
import CheckBox from './Form/InputTypes/CheckBoxInput';
import MultipleSelect from './Form/InputTypes/MultipleSelect';
import Table from './Table/Table';

const Reports = () => {
  const { report } = useParams();
  const [form] = Form.useForm();

  const [data, setData] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [tableColumns, setTableColumns] = React.useState([]);
  React.useEffect(() => {
    setTableColumns([]);
    setTableData([]);
    fetchData();
  }, [report]);

  const fetchData = async () => {
    const {
      data: { result },
    } = await Request({
      url: '/report/getReport',
      hideSuccess: true,
      body: { report_name: report },
    });
    setData(result);
  };
  const fixTableData = (data) => {
    let columns = [];
    Object.keys(data[0]).map((key) => {
      if (report === 'complaint_list') {
        if (complaint_list.find((element) => element === key)) {
          columns.push({
            title: key,
            dataIndex: key,
            key: key,
          });
        }
      } else {
        columns.push({
          title: key,
          dataIndex: key,
          key: key,
        });
      }
    });
    columns.unshift({
      title: '№',
      dataIndex: 'key',
      rowScope: 'row',
    });
    const dates = ['created_at', 'updated_at'];
    const datas = data.map((item, index) => {
      dates.forEach((el) => {
        if (item[el]) {
          const date = new Date(item[el]);
          item[el] = dateFormat(date);
        }
      });
      return { ...item, key: index + 1 };
    });
    setTableColumns(columns);
    setTableData(datas);
  };

  const handleReport = async () => {
    setTableData([]);
    setTableColumns([]);
    try {
      const values = await form.validateFields();
      let jsn = {
        report_name: report,
      };
      Object.keys(values).forEach((item) => {
        if (item === 'start_time' || item === 'end_time') {
          jsn[item] = values[item].toISOString();
        } else {
          jsn[item] = values[item];
        }
      });
      const {
        data: { result },
      } = await Request({
        url: '/report/getResult',
        hideSuccess: true,
        body: jsn,
      });
      fixTableData(result);
    } catch (error) {}
  };
  const handleExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Sheet')
      .addColumns(tableColumns)
      .addDataSource(tableData, {
        str2Percent: true,
      })
      .saveAs(`${data?.title ? data?.title : 'Excel'}.xlsx`);
  };
  return (
    <Container>
      <Form form={form}>
        <h2>{data?.title}</h2>

        <ReportOption>
          <ReportOptionHeader>
            <div className='optionHeader'>
              {data?.options.map((item, index) => {
                return (
                  (item.key === 'show_interval' && (
                    <ContainerOption key={index}>
                      <SelectInput
                        form={form}
                        label={item.label}
                        name={item.key}
                        options={changeForSelect(item.values)}
                        value={changeForSelectItem(item.values[0])}
                      />
                    </ContainerOption>
                  )) ||
                  (item.type === 'datepicker' && (
                    <ContainerOption key={index}>
                      <SelectDateTime
                        form={form}
                        label={item.label}
                        name={item.key}
                      />
                    </ContainerOption>
                  ))
                );
              })}
            </div>
            {/* {report !== 'order' && ( */}
            <div className='optionBottom'>
              {data?.options.map((item, index) => {
                return (
                  item.type === 'boolean' && (
                    <CheckBox
                      key={index}
                      form={form}
                      label={item.label}
                      name={item.key}
                      value={item.value}
                    />
                  )
                );
              })}
            </div>
            {/* )} */}
          </ReportOptionHeader>
          <MultipleOptionHeader>
            {data?.options?.map((item, index) => {
              return (
                item.type === 'multiple' && (
                  <ContainerOption key={index}>
                    <MultipleSelect
                      form={form}
                      label={item.label}
                      name={item.key}
                      options={changeForMultipleSelect(item.values)}
                      value={item.values.map((item) => item.storename)}
                      notFoundContent={'Эрх нээлгэнэ үү'}
                      required={true}
                    />
                  </ContainerOption>
                )
              );
            })}
          </MultipleOptionHeader>
        </ReportOption>
        <div className='actions'>
          <Button type='primary' size='medium' onClick={handleReport}>
            Get Report
          </Button>
          <Button
            disabled={tableData.length === 0}
            type='primary'
            ghost
            size='medium'
            onClick={handleExcel}
          >
            export to excel
          </Button>
        </div>
        <TableContainer>
          <Table dataSource={tableData} columns={tableColumns} />
        </TableContainer>
      </Form>
    </Container>
  );
};
const changeForSelect = (items) => {
  return items.map((item) => {
    const value = changeForSelectItem(item);
    return { value: value, label: item.name };
  });
};
const changeForSelectItem = (item) => {
  const end = item.end ? item.end : item.start;
  return `${item.start}to${end}`;
};
const changeForMultipleSelect = (items) => {
  return items.map((item) => {
    return { value: item.storename, label: item.storename };
  });
  // const end = item.end ? item.end : item.start;
  // return `${item.start}to${end}`;
};
const Container = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  .actions {
    display: flex;
    gap: 20px;
  }
`;
const MultipleOptionHeader = styled.div``;
const TableContainer = styled.div`
  margin-top: 30px;
`;
const ContainerOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  span {
    font-size: 13px;
  }
`;
const ReportOption = styled.div`
  margin-top: 30px;
  font-size: 12px;
  display: flex;
  gap: 30px;
  h2 {
    margin-bottom: 30px;
  }
`;
const ReportOptionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .optionHeader {
    display: flex;
    gap: 30px;
  }
  .optionBottom {
    display: flex;
    gap: 30px;
  }
`;
const ReportOptionMiddle = styled.div`
  // display: flex;
  // margin-top: 10px;
`;
export default Reports;
