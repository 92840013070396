import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';

const SelectInput = ({ name, form, options, required, ...props }) => {
  React.useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);

  return (
    <Form.Item
      style={{
        width: '240px',
      }}
      label={props.label}
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <Select
        width={200}
        placeholder={`${props.label} сонгоно уу`}
        allowClear
        options={options}
      />
    </Form.Item>
  );
};

export default SelectInput;
