import React from 'react';
import { TailSpin } from 'react-loader-spinner';
export const getReferenceContext = React.createContext();
export const ReferenceContextProvider = (props) => {
  const sidebar = React.useRef();
  const [sidebarWidth, setSidebarWidth] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    calcHeaderHeight();
  }, [sidebar]);
  const calcHeaderHeight = () => {
    const target = sidebar.current;
    if (target) setSidebarWidth(target.offsetWidth);
  };
  return (
    <getReferenceContext.Provider
      value={{
        sidebar,
        sidebarWidth,
        setIsLoading,
      }}
    >
      {isLoading && (
        <TailSpin
          height='40'
          width='40'
          color='#EA3038'
          ariaLabel='loading-spinner'
          radius='1'
          visible={true}
        />
      )}
      {props.children}
    </getReferenceContext.Provider>
  );
};
