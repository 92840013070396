import React from 'react';
import { BsKeyFill } from 'react-icons/bs';
import { EmailInput, PasswordInput } from './Form/InputTypes';
import { Button, Form } from 'antd';
import styled from 'styled-components';

const Login = ({ handleSubmit }) => {
  const [form] = Form.useForm();
  const handleButton = async () => {
    try {
      const data = await form.validateFields();
      handleSubmit(data);
    } catch (error) {
      //   NotificationManager.error('Мэйл хаяг эсвэл Нууц үг буруу байна');
    }
  };
  return (
    <Form form={form}>
      <Container>
        <img src='/logo.png' />
        <h2>Нэвтрэх</h2>
        <EmailInput required={true} name='email' form={form} />
        <PasswordInput
          required={true}
          name='password'
          placeholder='Нууц үгээ оруулна уу'
          form={form}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleButton();
          }}
          type='primary'
          icon={<BsKeyFill />}
          size={'medium'}
          htmlType='submit'
        >
          Нэвтрэх
        </Button>
      </Container>
    </Form>
  );
};
export default Login;
const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 220px;
  margin: auto;
  img {
    width: 100px;
  }
  h2 {
    text-align: center;
  }
  button {
    background: ${({ theme }) => theme.color};
  }
`;
