export const errorDetail = (err) => {
  return new Promise((resolve, reject) => {
    if (err.isAxiosError) {
      if (err.response) {
        // Server was able to send us a response, so this is an API Error.
        console.error('[API Error]:', err.response.data.errorMessage);
        resolve(err.response.data.errorMessage ?? err.response.data.error);
      } else {
        // Axios was not able to get a response at all. This is a Network-Level Error.
        console.error('[Network Error]: No Response Received At', err);
        resolve('Та интернет холболтоо шалгана уу');
      }
    } else {
      console.error('[Non-HTTP Error]:', err);
      resolve(err.message);
    }
  });
};
export const dateFormat = (string) => {
  const date = new Date(string);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};
