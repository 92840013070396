export const otherMenuItems = [
  {
    label: 'Profile',
    key: 'settings',
    children: [
      { label: 'Хэрэглэгчийн эрх', key: 'profile' },
      { label: 'Гарах', key: 'logout' },
    ],
  },
];
