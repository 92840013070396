import { Layout } from 'antd';
import { useState } from 'react';
const { Sider } = Layout;

const Sidebar = ({ sidebar, children }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Sider
      ref={sidebar}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      {children}
    </Sider>
  );
};
export default Sidebar;
