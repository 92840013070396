import { Checkbox, Form } from 'antd';
import React from 'react';

const CheckBox = ({ name, form, required, ...props }) => {
  React.useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);

  return (
    <Form.Item
      label={props.label}
      valuePropName='checked'
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <Checkbox />
    </Form.Item>
  );
};

export default CheckBox;
