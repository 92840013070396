import React, { useEffect } from 'react';
import { Form, Input } from 'antd';

const PasswordInput = ({ name, form, required, ...props }) => {
  useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);

  const validatePassword = (_, value) => {
    if (
      value &&
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(
        value
      )
    ) {
      return Promise.reject(
        'Нууц үг нь нэг том үсэг, нэг жижиг үсэг, нэг цифр, нэг тусгай тэмдэгт(!@#$%^&*) зэрэг дор хаяж 8 тэмдэгтээс бүрдэх ёстой.'
      );
    }
    return Promise.resolve();
  };

  return (
    <Form.Item
      label={props.label}
      name={name}
      style={{ width: '100%', marginBottom: 0 }}
      rules={[
        { required: required, message: 'Нууц үгээ оруулна уу !' },
        { validator: validatePassword },
      ]}
      {...props}
    >
      <Input {...props} type='password' />
    </Form.Item>
  );
};

export default PasswordInput;
