import withLoader from 'utils/WithLoader';
import { getAuthContext } from 'contexts/auth';
import Layout from '../Layout';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const AuthEntry = () => {
  const { loggedIn, checkUser } = React.useContext(getAuthContext);
  useEffect(() => {
    checkUser();
  }, [loggedIn]);

  return (
    loggedIn && (
      <Layout>
        <Outlet />
      </Layout>
    )
  );
};
export default withLoader(AuthEntry);

// export default AuthEntry;
