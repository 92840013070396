import React from 'react';
import { BsKeyFill } from 'react-icons/bs';
import { NotificationManager } from 'react-notifications';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { EmailInput, PasswordInput } from './Form/InputTypes';
import { Button, Form } from 'antd';
import styled from 'styled-components';

const ForceUpdatePass = ({ email, user, setLoggedIn }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const { password1, password2 } = await form.validateFields();
      try {
        if (password1 !== password2)
          return NotificationManager.warning('Нууц үг таарахгүй байна');
        await Auth.completeNewPassword(user, password1);
        NotificationManager.success('Амжилттай');
        setLoggedIn(true);
        navigate('/');
      } catch (error) {
        console.log({ error });
        NotificationManager.error(error.message, 'Error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form form={form}>
      <Container>
        <img src='/logo.png' />
        <h2>Нэвтрэх</h2>
        <EmailInput
          required={true}
          name='email'
          value={email}
          disabled
          form={form}
        />
        <PasswordInput
          required={true}
          name='password1'
          placeholder='Нууц үгээ оруулна уу'
          form={form}
        />
        <PasswordInput
          required={true}
          name='password2'
          placeholder='Нууц үгээ давтаж оруулна уу'
          form={form}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          type='primary'
          icon={<BsKeyFill />}
          size={'medium'}
          htmlType='submit'
        >
          Нэвтрэх
        </Button>
      </Container>
    </Form>
  );
};
export default ForceUpdatePass;
const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 220px;
  margin: auto;
  img {
    width: 100px;
  }
  h2 {
    text-align: center;
  }
  button {
    background: ${({ theme }) => theme.color};
  }
`;
